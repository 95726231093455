<template>
  <LayoutCard
    @click="
      () => {
        modal?.open()
      }
    "
    class="composite-intervention-card"
  >
    <ModalIntervention
      @duplicate="duplicate"
      :intervention="intervention"
      ref="modal"
      :request="request"
    />
    <ModalEditIntervention ref="edit_modal" />
    <ModalCreateIntervention ref="create_modal" />
    <LayoutModalMessage ref="delete_modal" @confirm="remove" label="Confirmer l'action ?">
      <p>L'intervention sera supprimée.</p>
    </LayoutModalMessage>
    <div class="left-group mt-10 mb-10">
      <div class="tags">
        <CommonChip
          v-for="skill_category in [...new Set(intervention.skills.map((item) => item.category))]"
          :key="skill_category"
          transparent
          color="dark"
          >{{ skill_category }}</CommonChip
        >
        <CommonChip transparent :color="getMissionStatus(intervention).color" :empty="v_mobile">
          {{ getMissionStatus(intervention).label }}
        </CommonChip>
      </div>
      <h5 class="title">
        {{ getInterventionName() }}
      </h5>
      <p v-if="intervention.complement" class="complement">{{ intervention.complement }}</p>
      <div class="status mt-10" v-if="v_desktop_p && !security.isAdmin">
        <p v-if="intervention.backer" class="bold">
          {{ intervention.backer }}
        </p>
        <LabelChip
          v-if="intervention.customer"
          :label="getClientStatus(intervention).label"
          :color="getClientStatus(intervention).color"
          :text="intervention.customer"
        />
        <LabelChip
          v-if="
            security.isAdmin && intervention.consultant && intervention.consultantStatus !== 'none'
          "
          :label="getConsultantStatus(intervention).label"
          :color="getConsultantStatus(intervention).color"
          :text="intervention.consultant.name"
        />
      </div>
      <div class="labels">
        <CommonIconLabel
          size="small"
          icon="Link"
          class="underline"
          @click.stop="openRelatedModal"
          v-if="(intervention.childs && intervention.childs.length > 0) || hasParent(intervention)"
        >
          {{ getRelatedInterventionText(intervention.childs!) }}
        </CommonIconLabel>
        <LabelDate v-else :date="intervention.date" />
        <LayoutModalMessage
          ref="related_intervention_modal"
          info
          :label="getRelatedInterventionText(intervention.childs!)"
          relatedInterventionModal
        >
          <template v-if="security.isConsultant">
            <template v-if="hasParent(intervention)">
              <p
                v-if="request_store.getRequestByInterventionID(intervention.parentIntervention!)"
                @click="scrollToIntervention(intervention.parentIntervention!)"
                class="related-date"
              >
                {{
                  getDayDate(
                    request_store.getRequestByInterventionID(intervention.parentIntervention!)!
                      .intervention.date
                  )
                }}
              </p>
            </template>
            <template v-else>
              <div v-for="intervention_id in intervention.childs" :key="intervention_id">
                <p
                  v-if="request_store.getRequestByInterventionID(intervention_id)"
                  @click="scrollToIntervention(intervention_id)"
                  class="related-date"
                >
                  {{
                    getDayDate(
                      request_store.getRequestByInterventionID(intervention_id!)!.intervention.date
                    )
                  }}
                </p>
              </div>
            </template>
          </template>
          <template v-if="security.isAdmin">
            <template v-if="hasParent(intervention)">
              <p
                v-if="intervention_store.getInterventionByID(intervention.parentIntervention!)"
                @click="scrollToIntervention(intervention.parentIntervention!)"
                class="related-date"
              >
                {{
                  getDayDate(
                    intervention_store.getInterventionByID(intervention.parentIntervention!)!.date
                  )
                }}
              </p>
            </template>
            <template v-else>
              <div v-for="intervention_id in intervention.childs" :key="intervention_id">
                <p
                  v-if="intervention_store.getInterventionByID(intervention_id)"
                  @click="scrollToIntervention(intervention_id)"
                  class="related-date"
                >
                  {{ getDayDate(intervention_store.getInterventionByID(intervention_id)!.date) }}
                </p>
              </div>
            </template>
          </template>
        </LayoutModalMessage>
        <CommonIconLabel v-if="intervention.dayPart" icon="CalendarClock">{{
          intervention.dayPart.toLowerCase() === 'full_day' ? 'Journée' : 'Demi-journée'
        }}</CommonIconLabel>
        <LabelTime
          v-if="intervention.startTime && intervention.endTime"
          :start="intervention.startTime"
          :end="intervention.endTime"
        />
        <CommonIconLabel v-if="isNight()" icon="Moon">Nuit</CommonIconLabel>
        <CommonIconLabel v-if="intervention.zone" icon="MapPin">
          {{ intervention.zone.level === 0 ? 'Distanciel' : 'Zone ' + intervention.zone.level }}
        </CommonIconLabel>
      </div>
    </div>
    <div class="status mt-10" v-if="v_desktop_p && security.isAdmin">
      <p v-if="intervention.backer" class="bold">
        {{ intervention.backer }}
      </p>
      <LabelChip
        v-if="intervention.customer"
        :label="getClientStatus(intervention).label"
        :color="getClientStatus(intervention).color"
        :text="intervention.customer"
      />
      <LabelChip
        v-if="
          security.isAdmin && intervention.consultant && intervention.consultantStatus !== 'none'
        "
        :label="getConsultantStatus(intervention).label"
        :color="getConsultantStatus(intervention).color"
        :text="intervention.consultant.name"
      />
    </div>
    <CommonChip
      v-if="
        !security.isAdmin &&
        request?.intervention.missionStatus === 'pending_documents' &&
        getRemainingDocuments() > 0
      "
      transparent
      color="danger"
      :empty="v_mobile"
      size="small"
    >
      Vous avez encore {{ getRemainingDocuments() }} documents à déposer avant le
      {{ getDocumentLimitDate() }}.
    </CommonChip>
    <CommonChip
      v-else-if="!security.isAdmin && request?.intervention.missionStatus === 'pending_documents'"
      transparent
      color="success"
      :empty="v_mobile"
      size="small"
    >
      Les documents ont étés déposés.
    </CommonChip>
    <div class="request mt-10 mb-10" v-if="!security.isAdmin && v_desktop">
      <template v-if="request?.status === 'pending'">
        <CommonButton @click.stop="accept" transparent color="success" icon="Check">
          J'accepte la mission
        </CommonButton>
        <CommonButton @click.stop="refuse" transparent color="danger" icon="X"
          >Je refuse la mission</CommonButton
        >
      </template>
      <template v-else>
        <CommonChip transparent color="success" :empty="v_mobile" size="large">Acceptée</CommonChip>
      </template>
      <div class="completion-states">
        <CommonChip
          v-if="
            (intervention.parentIntervention === undefined ||
              intervention.parentIntervention === null) &&
            !intervention.serviceCategories.some((category) => category.id === 5)
          "
          :color="intervention.consultantFormComplete ? 'success' : 'danger'"
          size="small"
        >
          {{ intervention.consultantFormComplete ? 'Intervenant OK' : 'Intervenant KO' }}
        </CommonChip>
        <CommonChip
          v-if="!intervention.serviceCategories.some((category) => category.id === 3)"
          :color="intervention.traineeFormComplete ? 'success' : 'danger'"
          size="small"
        >
          {{ intervention.traineeFormComplete ? 'Participants OK' : 'Participants KO' }}
        </CommonChip>
      </div>
    </div>
    <div class="actions" v-if="security.isAdmin">
      <CompositePopupMenu>
        <CommonIconButton
          icon="FileEdit"
          squared
          label="Modifier"
          color="dark"
          @click.stop="() => edit_modal?.edit(intervention)"
        />
        <CommonIconButton
          color="dark"
          icon="Copy"
          squared
          label="Dupliquer"
          @click.stop="duplicate(intervention)"
        />
        <CommonIconButton
          color="dark"
          icon="FileUp"
          squared
          label="Exporter en .pdf"
          @click.stop="() => intervention_store.export(intervention)"
        />
        <CommonIconButton
          color="dark"
          v-if="!intervention.deleted"
          icon="Trash2"
          squared
          label="Supprimer"
          @click.stop="() => delete_modal?.open()"
        />
      </CompositePopupMenu>
    </div>
  </LayoutCard>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import CommonChip from '../common/CommonChip.vue'
import CommonIconButton from '../common/CommonIconButton.vue'
import CommonIconLabel from '../common/CommonIconLabel.vue'
import LayoutCard from '../layout/LayoutCard.vue'
import LabelChip from './label/LabelChip.vue'
import LabelDate from './label/LabelDate.vue'
import LabelTime from './label/LabelTime.vue'
import CompositePopupMenu from './CompositePopupMenu.vue'
import type { Intervention, InterventionGroup, InterventionRequest } from 'types/api/intervention'
import {
  getClientStatus,
  getConsultantStatus,
  getMissionStatus
} from '@/services/service_intervention'
import ModalEditIntervention from '@/components/composite/modal/ModalEditIntervention.vue'
import { onMounted, ref } from 'vue'
import LayoutModalMessage from '../layout/LayoutModalMessage.vue'
import { useInterventionStore } from '@/stores/gae/store_intervention'
import { getTimeModelFromString, isTimeNight } from '@/services/service_date'
import ModalIntervention from './modal/ModalIntervention.vue'
import { useSecurityStore } from '@/stores/gae/store_security'
import CommonButton from '../common/CommonButton.vue'
import { useRequestStore } from '@/stores/gae/store_requests'
import ModalCreateIntervention from './modal/ModalCreateIntervention.vue'
import { useDynformStore } from '@/stores/gae/store_dynform'
import { getDayDate } from '@/services/service_intervention'
const intervention_store = useInterventionStore()
const store = useDynformStore()
const props = defineProps<{
  intervention: Intervention
  request?: InterventionRequest
}>()

defineEmits(['open'])
function remove() {
  intervention_store.delete(props.intervention)
}

const request_store = useRequestStore()
const security = useSecurityStore()
const breakpoint = useBreakpoints(breakpointsTailwind)
const v_desktop_p = breakpoint.greater('xl')
const v_desktop = breakpoint.greater('lg')
const v_mobile = breakpoint.smallerOrEqual('sm')
const edit_modal = ref<InstanceType<typeof ModalEditIntervention>>()
const delete_modal = ref<InstanceType<typeof LayoutModalMessage>>()
const related_intervention_modal = ref<InstanceType<typeof LayoutModalMessage>>()
const modal = ref<InstanceType<typeof ModalIntervention>>()
const create_modal = ref<InstanceType<typeof ModalCreateIntervention>>()
const groups = ref<InterventionGroup[]>()

function duplicate(intervention: Intervention) {
  if (modal.value) {
    create_modal.value?.create(intervention)
  }
}

function accept() {
  if (!props.request) return
  if (!confirm("Confirmer l'action ?")) return
  request_store.accept(props.request)
}

function refuse() {
  if (!props.request) return
  if (!confirm("Confirmer l'action ?")) return
  request_store.refuse(props.request)
}

function getInterventionName() {
  if (props.intervention.skills.length === 0) {
    return 'Compétence(s) à définir...'
  }

  if (props.intervention.skills.length > 2) {
    return props.intervention.skills.map((item) => item.shortname).join(', ')
  }
  return props.intervention.skills.map((item) => item.name).join(', ')
}

function isNight() {
  if (!props.intervention.startTime) return false
  const start = getTimeModelFromString(props.intervention.startTime)
  if (!start) return false
  return isTimeNight(start)
}

function getDocumentLimitDate() {
  const limitDate = new Date(props.intervention.date)
  limitDate.setDate(limitDate.getDate() + 2)
  return limitDate.toLocaleDateString('fr-FR')
}

function getRemainingDocuments() {
  const ignoredCategories = [
    'Autres documents',
    'Evaluation de la sensibilisation',
    'Rapport de formation'
  ]

  // Filtre si document categories
  const documentCategories = props.intervention.documentCategories.filter(
    (e) => e.acceptsLink || !ignoredCategories.includes(e.name)
  )
  const remainingDocuments = documentCategories
    .map((e) => (e.documents.length > 0 ? 0 : 1))
    .reduce((a: number, b: number) => a + b, 0)
  return remainingDocuments
}

function openRelatedModal() {
  related_intervention_modal.value?.open()
}

function scrollToIntervention(intervention_id: number) {
  const element = document.getElementById(`intervention_${intervention_id}`)
  if (element) {
    related_intervention_modal.value?.close()
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

function getRelatedInterventionText(intervention_childs: Array<number>): string {
  const count = intervention_childs.length
  const plural = count > 1 ? 's' : ''
  if (count === 0) return 'Interventions liées'
  return `${count} intervention${plural} liée${plural}`
}

function hasParent(intervention: Intervention) {
  return intervention.parentIntervention !== null && intervention.parentIntervention !== undefined
}

onMounted(async () => {
  store.dynform = undefined
  store.intervention_id = props.intervention.id
  groups.value = props.intervention.groups
  if (props.intervention.serviceCategories.length === 1) {
    store.selected_category = props.intervention.serviceCategories[0].id.toString()
  }
})
</script>

<style scoped>
.composite-intervention-card {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
  padding: 10px 20px;
}

.composite-intervention-card:hover {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  border-color: rgba(var(--vcrgb-dark), 0.5);
}

.popup {
  position: absolute;
}

.bold {
  font-weight: 500;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.labels {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .left-group {
    overflow: hidden;
    width: 100%;
  }

  .labels {
    display: inline;
  }

  .composite-intervention-card {
    gap: 10px;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.title {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.complement {
  margin-top: 2px;
  font-style: italic;
  color: rgba(var(--vcrgb-dark), 0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.status {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.request {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.customer {
  margin-top: 10px;
}

.completion-states {
  display: flex;
  gap: 10px;
}

.underline {
  text-decoration: underline;
}

.related-date {
  cursor: pointer;
}
</style>
