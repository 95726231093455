<template>
  <Teleport to="body">
    <div v-if="active" class="layout-modal-message-wrapper" :class="{ open: is_open }">
      <div
        ref="content"
        class="layout-modal-message"
        :class="{ 'related-intervention-modal': relatedInterventionModal }"
      >
        <div class="close-modal" v-if="relatedInterventionModal">
          <X @click="close" class="close-modal-icon" />
        </div>
        <h4>{{ label }}</h4>
        <slot />
        <div v-if="!relatedInterventionModal">
          <div class="info" v-if="info">
            <CommonButton color="dark" @click="close">Fermer</CommonButton>
          </div>
          <div class="actions" v-else>
            <CommonButton filled color="primary" @click="confirm">Confirmer</CommonButton>
            <CommonButton color="dark" @click="close">Annuler</CommonButton>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { nextTick, ref } from 'vue'
import { X } from 'lucide-vue-next'
import CommonButton from '../common/CommonButton.vue'

const is_open = ref(false)
const active = ref(false)
const content = ref<HTMLElement>()

defineProps<{
  modelValue?: boolean
  info?: boolean
  relatedInterventionModal?: boolean
  label: string
}>()

function confirm() {
  emits('confirm')
  close()
}

async function open() {
  document.body.style.overflowY = 'hidden'
  active.value = true
  await nextTick()
  is_open.value = true
  emits('update:modelValue', true)
}

async function close() {
  document.body.style.overflowY = ''
  is_open.value = false
  active.value = false
  setTimeout(() => {
    emits('update:modelValue', false)
  }, 50)
}

onClickOutside(content, () => {
  close()
})

defineExpose({ open, close, is_open })
const emits = defineEmits(['confirm', 'onClosed', 'update:modelValue'])
</script>

<style scoped>
.layout-modal-message-wrapper {
  transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  background-color: rgba(var(--vcrgb-dark), 0.8);
  pointer-events: none;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-modal-message-wrapper.open {
  transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 1;
  pointer-events: all;
  overflow-y: hidden;
}

.layout-modal-message {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  box-sizing: border-box;
  max-width: 400px;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.layout-modal-message-wrapper.open > .layout-modal-message {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: var(--vc-white);
}

.layout-modal-message-wrapper.open > .layout-modal-message:not(.related-intervention-modal) {
  overflow: scroll;
}

.related-intervention-modal {
  position: relative;
  width: 600px;
  min-height: 150px;
}

.related-intervention-modal .close-modal {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.related-intervention-modal .close-modal-icon {
  cursor: pointer;
}

.related-intervention-modal h4 {
  padding-right: 20px;
}

@media screen and (max-width: 1024px) {
  .layout-modal-message {
    width: calc(100% - 40px);
  }
}

.actions {
  display: flex;
  gap: 10px;
  justify-content: end;
}
</style>
